import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import BlogRoll from '../components/BlogContent/BlogRoll'
import MainBanner from "../components/Common/PageBanner.js";
import {graphql} from "gatsby";
import {Helmet} from 'react-helmet';

const PaginatedBlogRoll = ({data, pageContext}) => {
    const {edges: posts} = data.allMarkdownRemark;
    const siteUrl = data.site.siteMetadata.siteUrl;
    const ogImage = siteUrl + "/images/og/blog-og.png";
    const blogDescription = `Das LOGENTIS Blog (Seite  ${pageContext.currentPage}) gibt Ihnen einen Einblick in unsere Logistikprodukte für Versand und " +
      "das Lager, sowie Tipps und Tricks rund um die Intralogistik.`;
    const blogTitle = "Das LOGENTIS Blog";
    return (
        <Layout>
            <Navbar/>
            <Helmet>
                <title>{`${blogTitle} - Seite  ${pageContext.currentPage}`}</title>
                <meta name={"description"} content={blogDescription}/>
                <meta name={"image"} content={ogImage}/>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content={blogTitle}/>}
                <meta name="twitter:description" content={blogDescription}/>
                <meta name="twitter:image" content={ogImage}/>
                <meta property={"og:description"} content={blogDescription}/>
                <meta property={"og:image"} content={ogImage}/>
                <meta property={"og:title"} content={blogTitle}/>
            </Helmet>
          <MainBanner pageTitle={`${blogTitle} - Seite  ${pageContext.currentPage}`}
                      homePageUrl={"/"}
                      homePageText={"Home"}
                      activePageText={blogTitle}
                      bannerClassname={"page-title-area-smalltopspacing"}
                      withCallToAction={false}/>
            <BlogRoll posts={posts} numPages={pageContext.numPages} currentPage={pageContext.currentPage}/>
            <Footer/>
        </Layout>
    );
};

export default PaginatedBlogRoll;

export const pageQuery = graphql`
  query PaginatedBlogPosts($skip: Int!, $limit: Int!) {
     site {
        siteMetadata {
            siteUrl
        }
     }
     allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      filter: { frontmatter: {disabled: {eq: false}}, fields: {collection: {eq: "blog"}}}
      skip: $skip) 
      {
        edges {
            node {
              id
              html
              excerpt(pruneLength: 400)
              fields {
                slug
              }
              frontmatter {
                date(formatString: "DD.MM.YYYY")
                title
                description
                tags
                category
                featuredimage {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
                authorFull {
                    email
                    name
                    shortbio
                    title
                    authorimage {
                        childImageSharp {
                           gatsbyImageData(width: 50, placeholder: BLURRED)
                        }
                    }
                }
                socialmediaimage {
                  childImageSharp {
                    gatsbyImageData(width: 800)
                    resize(width: 1200) {
                      src
                    }
                  }
                }
              }
            }
        }
     }
  }
`
